import axios from 'axios';

export const setAuthToken = token => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = token;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
};

export const signup = (userFormData) => {
    return axios({method: 'POST', url: '/api/users/signup', data: userFormData, headers: { "Content-Type": "multipart/form-data" }});
};

export const login = (userData) => {
    return axios.post('/api/users/login', userData);
};